import Rio from "@retter/sdk";
import Cookies from "js-cookie";
import { URLS } from "../helpers/configs";
import { APP } from "../helpers/statics";
import { isProd } from "./utility";

export function getRioInstance() {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    projectId: URLS.PROJECT_ID,
    url: "api.sbux.retter.io",
    culture,
  });
  return rio;
}

export async function listGiftCardTypes() {
  let rio = getRioInstance();
  const giftCardObject = await rio.getCloudObject({
    classId: "GiftManager",
    useLocal: true,
    instanceId: "default",
  });

  let respListGiftCardTypes = await giftCardObject.call({
    method: "listGiftCardTypes",
    httpMethod: "get",
  });
  return respListGiftCardTypes;
}

export async function authenticateWithCustomToken(firebaseIdToken) {
  let rio = getRioInstance();
  const authenticatorObject = await rio.getCloudObject({
    classId: "Authenticator",
    useLocal: true,
    instanceId: "Authenticator",
  });

  let resp = await authenticatorObject.call({
    method: "generateCustomToken",
    body: {
      token: firebaseIdToken,
    },
  });
  return rio.authenticateWithCustomToken(resp.data.data.customToken);
}

export async function ticimaxSignIn(params) {
  let rio = getRioInstance();

  const authenticatorObject = await rio.getCloudObject({
    classId: "Ticimax",
    useLocal: true,
    instanceId: "default",
  });

  let deviceId = Cookies.get("deviceId");
  if (!deviceId) {
    deviceId = Math.floor(Math.random() * 10000);
    Cookies.set("deviceId", deviceId);
  }

  let resp = await authenticatorObject.call({
    method: "signIn",
    body: {
      deviceId: deviceId.toString(),
      ...params, // email - phoneNumber - password
    },
  });
  return resp;
}

export async function rioSignUp(params) {
  let rio = getRioInstance();

  const authenticatorObject = await rio.getCloudObject({
    classId: "Authenticator",
    useLocal: true,
    instanceId: "default",
  });

  let deviceId = Cookies.get("deviceId");
  if (!deviceId) {
    deviceId = Math.floor(Math.random() * 10000);
    Cookies.set("deviceId", deviceId);
  }

  let resp = await authenticatorObject.call({
    method: "signUp",
    body: {
      ...params,
      deviceId: deviceId.toString(),
    },
  });
  return resp;
}

export function rioSignOut() {
  let rio = getRioInstance();
  rio.signOut().then(() => {
    console.log("rioSignOut success");
  });
}

export async function queryGiftCard(giftCardNo) {
  let rio = getRioInstance();
  const giftCardObject = await rio.getCloudObject({
    classId: "GiftManager",
    useLocal: true,
    instanceId: "default",
  });

  let respListGiftCardTypes = await giftCardObject.call({
    method: "queryGiftCard",
    body: {
      giftCardNo,
    },
  });
  return respListGiftCardTypes;
}

export async function getMasterpassToken() {
  let rio = getRioInstance();
  let currentUser = await rio.getCurrentUser();

  const userObject = await rio.getCloudObject({
    classId: "User",
    instanceId: currentUser?.userId,
    useLocal: true,
  });

  let resp = await userObject.call({
    method: "getMasterpassToken",
    httpMethod: "get",
  });
  return resp;
}

export async function buy(params) {
  let rio = getRioInstance();
  const giftManagerObject = await rio.getCloudObject({
    classId: "GiftManager",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await giftManagerObject.call({
    method: "buy",
    body: params,
  });
  console.log("buy resp is", resp);
  return resp;
}

export async function commitPayment(instanceId, purchaseToken) {
  let rio = getRioInstance();
  console.log("commitPayment called", instanceId, purchaseToken);
  const billingObject = await rio.getCloudObject({
    classId: "Billing",
    instanceId,
    useLocal: true,
  });

  let resp = await billingObject.call({
    method: "commitPayment",
    body: {
      purchaseToken,
    },
  });
  console.log("commitPayment resp is", resp);
  return resp;
}

export async function ticimaxSignUp(token) {
  let rio = getRioInstance();

  const ticimaxObject = await rio.getCloudObject({
    classId: "Ticimax",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await ticimaxObject.call({
    method: "signUp",
    body: {
      token,
    },
  });
  return resp;
}

export async function getCatalog() {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "CatalogManager",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await cloudObject.call({
    method: "get",
    httpMethod: "get",
  });
  return resp;
}

export async function earnStarWithPromotion(promoCode) {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "User",
    useLocal: true,
    instanceId: "default",
    httpMethod: "post",
  });

  let resp = await cloudObject.call({
    method: "earnStarWithPromotion",
    body: {
      promoCode,
    },
    headers: {
      "client-agent": "web-" + APP.version,
    },
  });
  return resp;
}

export async function getStoreSurvey() {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getStoreSurvey",
    httpMethod: "get",
  });
  return resp;
}

export async function submitStoreSurvey(params) {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "post",
  });

  let resp = await cloudObject.call({
    method: "submitStoreSurvey",
    body: {
      ...params,
    },
  });
  return resp;
}

export async function getContactForm() {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getContactForm",
    httpMethod: "get",
  });
  return resp;
}

export async function submitContactForm(params) {
  let rio = getRioInstance();

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "post",
  });

  let resp = await cloudObject.call({
    method: "submitContactForm",
    body: {
      ...params,
    },
    headers: {
      "client-agent": "web-" + APP.version,
    },
  });
  return resp;
}

export async function getStandardCmsPage(pageName) {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getStandardCmsPage",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
    queryStringParams: {
      type: pageName,
    },
  });
  return resp;
}

export async function getSingleCmsPage(pageName, blockId) {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getSingleCmsPage",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
    queryStringParams: {
      type: pageName,
      blockId,
    },
  });
  return resp;
}

export async function getHomepageBanners(pageName) {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getHomepageBanners",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
  });
  return resp;
}

export async function getMainVideoUrl() {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getHomepageVideo",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
  });
  return resp;
}

export async function getMenu() {
  let rio = getRioInstance();
  const cloudObject = await rio.getCloudObject({
    classId: "ProductManager",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await cloudObject.call({
    method: "getMenu",
    httpMethod: "get",
    culture: Cookies.get("preferredLocale") == "en" ? "en_US" : "tr_TR",
  });
  return resp;
}

export async function getStores() {
  let rio = getRioInstance();
  const cloudObject = await rio.getCloudObject({
    classId: "StoreManager",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await cloudObject.call({
    method: "getStores",
    httpMethod: "get",
  });
  return resp;
}

export async function getFilters() {
  let rio = getRioInstance();
  const cloudObject = await rio.getCloudObject({
    classId: "StoreManager",
    useLocal: true,
    instanceId: "default",
  });

  let resp = await cloudObject.call({
    method: "getFilters",
    httpMethod: "get",
  });
  return resp;
}

export async function getMenuImages(menuId) {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getMenuImages",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
    queryStringParams: {
      type: menuId,
    },
  });
  return resp;
}

export async function getWebsiteBanners() {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "ContentManager",
    useLocal: true,
    instanceId: "default",
    httpMethod: "get",
  });

  let resp = await cloudObject.call({
    method: "getPopup",
    platform: "web-" + APP.version,
    culture,
    httpMethod: "get",
  });
  return resp;
}

export async function rioAnswerChallange(uid, challengeId, answer) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const authenticatorObject = await rio.getCloudObject({
    classId: "User",
    useLocal: true,
    instanceId: uid,
  });

  let resp = await authenticatorObject.call({
    method: "answerChallenge",
    body: {
      challengeId,
      answer,
    },
  });
  return resp;
}

export async function rioGetUserInfo(uid) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const authenticatorObject = await rio.getCloudObject({
    classId: "Wallet",
    useLocal: true,
    instanceId: uid,
  });

  let resp = await authenticatorObject.call({
    method: "getUserInfoForWeb",
  });
  return resp;
}

export async function rioUpdatePassword(uid, body) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const authenticatorObject = await rio.getCloudObject({
    classId: "User",
    useLocal: true,
    instanceId: uid,
  });

  let resp = await authenticatorObject.call({
    method: "updatePassword",
    body: {
      oldPassword: body.oldPassword,
      newPassword: body.password,
    },
  });
  return resp;
}

export async function rioResetPassword(
  instanceId,
  challengeId,
  challengeAnswer,
  newPassword
) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const authenticatorObject = await rio.getCloudObject({
    classId: "User",
    useLocal: true,
    instanceId,
  });

  let resp = await authenticatorObject.call({
    method: "resetPassword",
    body: {
      challengeId,
      challengeAnswer,
      newPassword,
    },
  });
  return resp;
}

export async function rioValidatePassword(
  instanceId,
  challengeId,
  challengeAnswer,
  newPassword
) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const authenticatorObject = await rio.getCloudObject({
    classId: "User",
    useLocal: true,
    instanceId,
  });

  let resp = await authenticatorObject.call({
    method: "validatePassword",
    body: {
      challengeId,
      challengeAnswer,
      newPassword,
    },
  });
  return resp;
}

export async function donateStarNewStage(body, uid) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const cloudObject = await rio.getCloudObject({
    classId: "Wallet",
    instanceId: uid,
    useLocal: true,
  });

  let resp = await cloudObject.call({
    method: "donateStar",
    culture,
    body,
  });
  return resp;
}

export async function donateStarOldStage(body) {
  let rio = getRioInstance();
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";

  const cloudObject = await rio.getCloudObject({
    classId: "User",
    instanceId: "default",
    useLocal: true,
  });

  let resp = await cloudObject.call({
    method: "donateStar",
    culture,
    body,
  });
  return resp;
}

export async function getDonationCompanies() {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const cloudObject = await rio.getCloudObject({
    classId: "Wallet",
    instanceId: "default",
    useLocal: true,
  });

  let resp = await cloudObject.call({
    method: "getDonationCompanies",
    httpMethod: "get",
    culture,
  });
  return resp;
}

export async function verifyStudentCertificate(uid, file) {
  let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr";
  const rio = Rio.getInstance({
    url: "api.sbuxtr.com",
    projectId: isProd() ? "asnt778q" : "g7r5gvep",
    culture,
  });

  const userObject = await rio.getCloudObject({
    classId: "User",
    instanceId: uid,
    useLocal: true,
  });

  let resp = await userObject.call({
    method: "verifyStudentCertificate",
    body: {
      file,
    },
  });
  return resp;
}
